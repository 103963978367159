// extracted by mini-css-extract-plugin
export var ctaButton = "Celebrity-module--cta-button--4ac3d";
export var ctaText = "Celebrity-module--cta-text--fd625";
export var expandButton = "Celebrity-module--expand-button--d99e6";
export var ghostButton = "Celebrity-module--ghost-button--4afda";
export var ghostText = "Celebrity-module--ghost-text--cee8c";
export var gradientBorder = "Celebrity-module--gradient-border--98585";
export var infoWrapper = "Celebrity-module--info-wrapper--d39fb";
export var information = "Celebrity-module--information--3b5e8";
export var leftInfo = "Celebrity-module--left-info--35e7a";
export var location = "Celebrity-module--location--d332e";
export var name = "Celebrity-module--name--0ed08";
export var number = "Celebrity-module--number--b5f59";
export var row = "Celebrity-module--row--0afd5";
export var rowReverse = "Celebrity-module--row-reverse--a34a8";
export var wrapper = "Celebrity-module--wrapper--a38f5";
import React from 'react'
import FormFreeConsultation from '../../../global/forms/FreeConsultation'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './CustomLCF05.module.scss'

const CustomLeadContentForm05 = ({
  wrapperClassName,
  formClassName,
  title = 'Free Consultation',
  subtitle = 'Get in touch with us for a',
  titleClassName = 'heading-5',
  subtitleClassName = 'subtitle-7',
}) => {
  const { formModelMan } = useStaticQuery(graphql`
    query FormImage {
      formModelMan: file(relativePath: { eq: "form/LCF05/model-man.jpg" }) {
        id
        ...FormImageOptimize
      }
    }

    fragment FormImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <div className={cx.left}>
        <div className={`${cx.formContainer} lfc05-container`}>
          <h2>
            <span className={subtitleClassName}>{subtitle}</span>
            <span className={`heading-5 ${titleClassName}`}>{title}</span>
          </h2>

          <div className={clsx([cx.form], formClassName, 'lfc05-form')}>
            <FormFreeConsultation
              submitLabel="I want a stunning website!"
              leadName="Bottom FSU"
              isEmailPhoneMerger={true}
              isMessageTextarea={true}
            />
          </div>
        </div>
      </div>
      <div className={cx.modelWrapper}>
        <ElementImageFile
          className={cx.model}
          src={getImage(formModelMan)}
          alt={subtitle}
          lazy={true}
        />
      </div>
    </section>
  )
}

export default CustomLeadContentForm05

import React from 'react'
import * as cx from './Celebrity.module.scss'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import Buttons from '../../../global/buttons/Buttons'
import BackgroundLeft from '/content/assets/celebrity-websites/background-left.png'
import BackgroundRight from '/content/assets/celebrity-websites/background-right.png'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import parse from 'html-react-parser'

const Celebrity = ({ celebrityImage, details, index, detailsClassName }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const isEven = index % 2
  const openLink = (link) => {
    window.open(link, '_blank')
  }

  const modalFunc = React.useRef()

  return (
    <>
      <section
        style={{
          backgroundImage: `url(${isEven ? BackgroundRight : BackgroundLeft})`,
          backgroundSize: 'cover',
        }}
        className={`${cx.wrapper} ${isEven ? cx.rowReverse : cx.row}`}
      >
        <div>
          <img className="object-scale-down" src={celebrityImage} />
        </div>
        <div
          className={`${cx.infoWrapper} ${
            isEven && cx.leftInfo
          } ${detailsClassName}`}
        >
          <div className="pb-6">
            <p className={cx.number}>{details.number}</p>
            <div className="flex items-center pb-[0.188rem] md:pb-4 lg:pb-3.5">
              <div className={cx.gradientBorder} />
              <h7 className={cx.name}>{parse(details.name)}</h7>
            </div>
            <p className={`subtitle-4 ${cx.location}`}>{details.location}</p>
          </div>
          <div className={cx.information}>
            <p>{details.description}</p>
            <div
              className={`overflow-hidden ${
                isExpanded ? 'h-auto' : 'h-0'
              } md:h-auto`}
            >
              <br />
              <p className="subtitle-7">career highlights</p>
              <br />
              <p className="whitespace-pre-wrap">{details.careerHighlights}</p>
              <br />
              <p className="subtitle-7">website overview</p>
              <br />
              <p className="whitespace-pre-wrap">{details.websiteOverview}</p>
            </div>
            <button
              className={cx.expandButton}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <p>READ {isExpanded ? 'LESS' : 'MORE'}</p>
              <div
                className={`${isExpanded ? 'rotate-0' : 'rotate-180'} w-[12%]`}
              >
                <SvgArrowAlt />
              </div>
            </button>
            <Buttons
              ctaClassName={cx.ctaButton}
              ghostClassName={cx.ghostButton}
              ctaTextClassName={cx.ctaText}
              ghostTextClassName={cx.ghostText}
              ghostText={'VIEW WEBSITE'}
              ctaText={'I WANT TO LEARN MORE'}
              wrapperClassName="pt-8 lg:flex"
              ghostOnClick={() => openLink(details.website)}
              ctaOnClick={() => modalFunc.current.openModal()}
            />
          </div>
        </div>
      </section>
      <ModalForm
        childFunc={modalFunc}
        template="m1"
        title="Sign Up Today For A 
        Free Consultation"
        description=""
        type="a"
      >
        <FormSignUp
          submitLabel="Get Started Today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
    </>
  )
}

export default Celebrity

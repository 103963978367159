import React from 'react'
import { graphql } from 'gatsby'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import * as cx from './CelebrityWebsites.module.scss'
import Banner from '@components/global/banner/Banner'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import useMedia, { media } from '@hooks/useMedia'
import Celebrity from '@components/pages/celebrity-websites/Celebrity'
import ArrowNavigation from '@components/pages/celebrity-websites/ArrowNavigation'
import LeadContentForm05 from '@components/global/forms/bottom/LeadContentForm05'
import CustomLeadContentForm05 from '@components/pages/celebrity-websites/CustomLCF05'

const TopCelebrityRealEstateAgentWebsites = ({
  data: { page, laptopPlaceholder },
  pageContext: { seo, uri },
}) => {
  const isTablet = useMedia(media.tablet)
  // Celebrity Images
  const celebrityImagesLaptop = page?.celebrityWebsites?.gallery?.items.map(
    (item, key) => {
      return {
        url: item?.image?.url,
      }
    }
  )
  // Celebrity Tablet Images
  const celebrityImagesTablet = page?.celebrityWebsites?.gallery?.items.map(
    (item, key) => {
      return {
        url: item?.tablet?.url,
      }
    }
  )
  // Celebrity Text Data
  const celebritiesData = page?.celebrityWebsites?.gallery?.items.map(
    (item, key) => {
      return {
        number: item.number,
        name: item.name,
        location: item.location,
        description: item.description,
        careerHighlights: item.highlights,
        websiteOverview: item.overview,
        website: item.site_url,
      }
    }
  )
  // Banner Data
  const bannerData = {
    layout: { container: 'product-gallery', devices: 'PhoneTabletLaptopV2' },
    heading: {
      text: page?.celebrityWebsites?.banner?.banner_title,
      className: 'heading-1',
      tabletClassName: 'heading-3',
    },
    subheading: {
      text: page?.celebrityWebsites?.banner?.banner_description,
      className: 'subtitle-1',
      tabletClassName: 'default-body',
    },
    form: {
      titleClassName: 'subtitle-2',
      subheadingClassName: 'subtitle-6',
      buttonClassName: 'primary-button large',
      buttonTabletClassName: 'primary-button small',
    },
  }

  const celebrityImages = isTablet
    ? celebrityImagesTablet
    : celebrityImagesLaptop

  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        bgGradientShow
        bgGradient={cx.bgGradient}
        wrapperClassName={cx.banner}
        layout={bannerData.layout.container}
        formLayout={bannerData.form.layout}
        breadcrumbs={page.breadcrumbs}
        title={bannerData.heading.text}
        description={bannerData.subheading.text}
        buttonClassName={
          isTablet
            ? bannerData.form.buttonTabletClassName
            : bannerData.form.buttonClassName
        }
        titleClassName={
          isTablet
            ? bannerData.heading.tabletClassName
            : bannerData.heading.className
        }
        subtitle2ClassName={
          isTablet
            ? bannerData.subheading.tabletClassName
            : bannerData.subheading.className
        }
        formHeadingClassName={bannerData.form.subheadingClassName}
        devices={{
          layout: bannerData.layout.devices,
          laptopPlaceholder: extractImage(laptopPlaceholder),
          mobile:
            page?.celebrityWebsites?.banner?.banner_mobile?.url instanceof
            Object
              ? extractImage(
                  page?.celebrityWebsites?.banner?.banner_mobile?.url
                )
              : page?.celebrityWebsites?.banner?.banner_mobile?.url,
          tablet:
            page?.celebrityWebsites?.banner?.banner_tablet?.url instanceof
            Object
              ? extractImage(
                  page?.celebrityWebsites?.banner?.banner_tablet?.url
                )
              : page?.celebrityWebsites?.banner?.banner_tablet?.url,
          laptop:
            page?.celebrityWebsites?.banner?.banner_laptop?.url instanceof
            Object
              ? extractImage(
                  page?.celebrityWebsites?.banner?.banner_laptop?.url
                )
              : page?.celebrityWebsites?.banner?.banner_laptop?.url,
        }}
      />
      {celebritiesData.map((celebrity, key) => (
        <Celebrity
          key={key}
          celebrityImage={
            celebrityImages[key].url === undefined ||
            celebrityImages[key].url === ''
              ? extractImage(celebrityImages[key])
              : celebrityImages[key].url
          }
          details={celebrity}
          index={key}
        />
      ))}
      <ArrowNavigation
        urlRight={page?.celebrityWebsites?.navigation?.next_link}
        urlLeft={page?.celebrityWebsites?.navigation?.prev_link}
      />
      <LeadContentForm05
        wrapperClassName={`${cx.contentFormWrapper} ${cx.originalForm}`}
        formClassName={cx.bottomForm}
        titleClassName={`heading-5 ${cx.formTitle}`}
        title={`Free \nConsultation`}
      />
      <CustomLeadContentForm05
        wrapperClassName={`${cx.contentFormWrapper} ${cx.customForm}`}
        formClassName={cx.bottomForm}
        titleClassName={cx.formTitle}
      />
    </LayoutInnerPage>
  )
}

export default TopCelebrityRealEstateAgentWebsites

export const pageQuery = graphql`
  query TopCelebrityRealEstateWebsite($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
      celebrityWebsites {
        banner {
          banner_title
          banner_description
          banner_form_title
          banner_form_subtitle
          banner_form_submit
          banner_laptop {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          banner_tablet {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          banner_mobile {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        gallery {
          items {
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            name
            number
            location
            description
            overview
            highlights
            site_name
            site_url
            learn_name
            learn_url
          }
        }
        navigation: navigaton {
          prev_link
          next_link
        }
      }
    }
    laptopPlaceholder: file(
      relativePath: { eq: "celebrity-websites/laptop-placeholder.png" }
    ) {
      id
      ...CelebrityWebsite2ImageOptimize
    }
  }

  fragment CelebrityWebsite2ImageOptimize on File {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
    }
  }
`

import React from 'react'
import { Link } from 'gatsby'
import SvgArrowInline from '@svg/arrow.inline'
import * as cx from './ArrowNavigation.module.scss'

const ArrowNavigation = ({ urlLeft, urlRight }) => {
  const ArrowButton = ({ className, url }) => {
    return (
      <Link href={url} className={`${className} ${cx.arrowButtonWrapper}`}>
        <div className={cx.innerCircle}>
          <div className={cx.arrowSvg}>
            <SvgArrowInline />
          </div>
        </div>
      </Link>
    )
  }

  return (
    <div className={cx.wrapper}>
      {urlLeft && <ArrowButton className={cx.leftArrow} url={urlLeft} />}
      <span className={cx.text}>MORE CELEBRITY WEBSITES</span>
      {urlRight && <ArrowButton url={urlRight} />}
    </div>
  )
}

export default ArrowNavigation
